import React from "react";
import { Layout } from "../components";

const PolitykaCookies = ({ pageContext: { slug } }) => {
  return (
    <Layout
      seo={{
        title: "Polityka prywatności",
        href: slug,
        lang: "pl",
        noindex: true,
      }}
    >
      <section className="container-fluid default_section_1">
        <div className="text-center">
          <h1>Polityka Plików Cookies</h1>
        </div>
        <div className="wrapper">
          <p>
            Niniejsza Polityka dotycząca Ciasteczek odnosi się do strony when.pl
            Przez używanie serwisu when.pl wyrażasz zgodę na używanie ciasteczek
            zgodnie z tą Polityką Ciasteczek. Jeżeli nie zgadzasz się na
            używanie przez nas ciasteczek, powinieneś zmienić ustawienia swojej
            przeglądarki w odpowiedni sposób lub zrezygnować z używania strony
            when.pl.
          </p>
          <h3>Co to są ciasteczka?</h3>
          <p>
            Ciasteczka (ang. cookies) to niewielkie pliki, zapisywane i
            przechowywane na twoim komputerze, tablecie lub smartphonie podczas
            gdy odwiedzasz różne strony w internecie. Ciasteczko zazwyczaj
            zawiera nazwę strony internetowej, z której pochodzi, „długość
            życia” ciasteczka (to znaczy czas jego istnienia), oraz przypadkowo
            wygenerowany unikalny numer służący do identyfikacji przeglądarki, z
            jakiej następuje połączenie ze stroną internetową.
          </p>
          <h3>Do czego używamy ciasteczek?</h3>
          <p>
            when.pl używa ciasteczek w różnych celach: by serwis działał
            szybciej i był łatwiejszy w użyciu, do zbierania anonimowych,
            zagregowanych statystyk, które pozwalają nam zrozumieć jak ludzie
            używają naszych stron i pomagają w poprawianiu ich funkcjonalności i
            zawartości. Używając ciasteczek w wyżej opisany sposób nigdy nie
            identyfikujemy tożsamości użytkowników na podstawie informacji
            przechowywanych w ciasteczkach.
          </p>
          <h3>Jak długo przechowywane są dane w ciasteczkach?</h3>
          <p>
            Na stronie when.pl mogą być używane dwa rodzaje ciasteczek – sesyjne
            oraz stałe. Te pierwsze pozostają na Twoim urządzeniu jedynie
            podczas korzystania ze strony when.pl. Ciasteczka stałe pozostają na
            Twoim urządzeniu tak długo jak długo mają ustawiony czas życia lub
            do momentu kiedy je usuniesz.
          </p>
          <h3>Rodzaje ciasteczek używanych na stronie when.pl</h3>
          <p>
            Konieczne do działania stron – Niezbędne do prawidłowego
            funkcjonowania strony when.pl, pozwalają Ci na poruszanie się po
            nich oraz używanie ich elementów. Przykładowo mogą zapamiętywać
            poprzednie czynności (np. otwarte teksty) podczas wracania na stronę
            w tej samej sesji.
          </p>
          <p>
            Poprawiające wydajność – Zbieranie informacji o tym jak odwiedzający
            korzystają ze strony when.pl poprzez dostarczanie informacji na
            temat obszarów które odwiedzają, czasu jaki na nich spędzają oraz
            problemów jakie na nich napotykają, jak np. komunikaty o błędach. To
            pozwala nam poprawiać działanie strony when.pl.
          </p>
          <h3>Czy używamy ciasteczek podmiotów trzecich?</h3>
          <p>
            Tak, korzystając z serwisu when.pl możesz otrzymywać ciasteczka
            pochodzące od współpracujących z when.pl podmiotów trzecich takich
            jak np. Facebook czy Google. Więcej informacji na temat tych
            ciasteczek możesz znaleźć na stronach internetowych poszczególnych
            podmiotów trzecich.
          </p>
          <h3>
            W jaki sposób mogę zmienić ustawienia dot. ciasteczek albo je
            usunąć?
          </h3>
          <p>
            Większość przeglądarek internetowych jest początkowo ustawionych na
            automatyczne przyjmowanie ciasteczek. Możesz jednak zmienić
            ustawienia przeglądarki tak, aby ciasteczka były blokowane – w
            całości lub w jakiejś części, np. tylko od stron trzecich, albo aby
            każdorazowo otrzymywać komunikat w momencie kiedy ciasteczka są
            wysyłane na Twoje urządzenie. Pamiętaj jednak, że jeżeli zablokujesz
            używane przez nas ciasteczka, może to negatywnie wpłynąć na wygodę
            korzystania ze strony when.pl, na przykład możesz nie być w stanie
            odwiedzić pewnych obszarów strony when.pl bądź nie otrzymywać
            spersonalizowanych informacji podczas ich przeglądania.
            Uniemożliwisz nam także zbieranie anonimowych informacji nt.
            używania naszych stron w celu stałego poprawiania zawartości strony
            when.pl.
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default PolitykaCookies;
